import React, { useState } from "react";
import env from "react-dotenv";

export default function Contact() {
  const API_URL = env.REACT_APP_API_URL;
  const [status, setStatus] = useState("Submit");
  const [contactForm, setContactForm] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
    };
    let response = await fetch(API_URL + "contact", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(details),
    });
    setStatus("Submit");
    let result = await response.json();
    // alert(result.status);
    // console.log(result)
    if (result.status === "Message Sent") {
      resetContactForm();
    }
    setStatus(result.status);
  };

  const resetContactForm = () => {
    setContactForm({ name: "", email: "", message: "" });
  };
  const handleContactFormChange = (e) => {
    setContactForm((prevFormData) => {
      return {
        ...prevFormData,
        [e.target.name]: e.target.value,
      };
    });
  };

  return (
    <section id="contact" className="relative">
      <div className="container px-5 py-10 mx-auto flex sm:flex-nowrap flex-wrap">
        <form
          onSubmit={handleSubmit}
          name="contact"
          className="lg:w-1/3 md:w-1/2 flex flex-col md:ml-auto w-full md:py-8 mt-8 md:mt-0"
        >
          <h2 className="text-white sm:text-4xl text-3xl mb-1 font-medium title-font">
            Contact Me
          </h2>
          <p className="leading-relaxed mb-5">
            Have a project in mind or just want to chat about Web Development or
            Cybersecurity? I'd love to hear from you! Feel free to reach out:
          </p>
          <div className="relative mb-4">
            <label htmlFor="name" className="leading-7 text-sm text-gray-400">
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              value={contactForm.name}
              onChange={handleContactFormChange}
              autoComplete="off"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label htmlFor="email" className="leading-7 text-sm text-gray-400">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={contactForm.email}
              onChange={handleContactFormChange}
              autoComplete="off"
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 text-base outline-none text-gray-100 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            />
          </div>
          <div className="relative mb-4">
            <label
              htmlFor="message"
              className="leading-7 text-sm text-gray-400"
            >
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={contactForm.message}
              onChange={handleContactFormChange}
              className="w-full bg-gray-800 rounded border border-gray-700 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-900 h-32 text-base outline-none text-gray-100 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
            />
          </div>

          {status === "Error" || status === "Invalid" ? (
            <div
              className="p-4 mb-4 text-sm text-yellow-600 rounded-lg bg-grey-800 dark:bg-gray-800 dark:text-yellow-300"
              role="alert"
            >
              <span className="font-medium">Submission failed.</span> Change a
              few things up and try submitting again.
            </div>
          ) : status === "Message Sent" ? (
            <div
              className="p-4 mb-4 text-sm text-green-600 rounded-lg bg-grey-800 dark:bg-gray-800 dark:text-green-400"
              role="alert"
            >
              <span className="font-medium">Thanks for getting in touch!</span>{" "}
              Your message has been successfully sent, and I look forward to
              connecting with you shortly.
            </div>
          ) : (
            ""
          )}

          <button
            type="submit"
            disabled={status == "Sending..."}
            className="text-white bg-indigo-500 border-0 py-2 px-6 enabled:focus:outline-none enabled:hover:bg-indigo-600 rounded text-lg"
          >
            {status == "Sending..." ? status : "Submit"}
          </button>
        </form>
      </div>
    </section>
  );
}
