export const projects = [
  {
    title: "FileInvite",
    subtitle: "A secure document collection web app.",
    description:
      "Easily request files, documents, live forms & signatures from your clients with FileInvite.",
    image: "./project-1.gif",
    link: "https://fileinvite.com",
  },
  {
    title: "Opinsta",
    subtitle:
      "Innovative technology solutions that revolutionise employee engagement and customer experience.",
    description: "Custom projects for Rail and Transport industries",
    image: "./project-1.gif",
    link: "https://opinsta.com",
  },
];

export const skills = [
  "Laravel",
  "PHP",
  "JavaScript",
  "jQuery",
  "React",
  "Node.js",
  "SQL",
  "NoSQL",
  "Redis",
  "Python",
  "AWS",
  "Linux",
  "Certified in Cybersecurity",
];
